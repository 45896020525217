import { Card, CardContent } from "@mui/material";
import {
  DataGrid,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridRowModes,
} from "@mui/x-data-grid";
import React, { useRef, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { generalErrorMessage, savedDataMessage } from "../../rcAuto/constant";
import { useDispatch } from "react-redux";
import { showSnackbar } from "../../../../redux/slices/snackbar";

const Settings = ({ info, codiceServizio, codiceScope }) => {
  const [pageSize, setPageSize] = useState(5);
  const servizio = useRef(codiceServizio);
  const scope = useRef(codiceScope);
  const [rowModesModel, setRowModesModel] = useState({});
  const dispatch = useDispatch();

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  };

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow };
    console.log(updatedRow.idUid);
    //Impostare la nuova api
    servicesNewUpdate({
      codice: servizio.current,
      idUID: updatedRow.idUid,
      scope: scope.current,
      idStato: updatedRow.stato,
      nome: updatedRow.nome,
      us: updatedRow.us,
      pd: updatedRow.pd,
    });

    return updatedRow;
  };

  //Columns DataGrid
  const columns = [
    {
      field: "idUid",
      headerName: "idUidBot",
      minWidth: 150,
      width: 200,
      flex: 1,
      editable: true,
    },
    {
      field: "nome",
      headerName: "Nome",
      minWidth: 150,
      width: 200,
      flex: 1,
      editable: true,
    },
    {
      field: "stato",
      headerName: "Stato",
      minWidth: 75,
      width: 125,
      flex: 1,
      editable: true,
      type: "singleSelect",
      valueOptions: [
        { value: 1, label: "Attivo" },
        { value: 0, label: "Non Attivo" },
      ],
      valueFormatter: ({ value, field, api }) => {
        const colDef = api.getColumn(field);
        const option = colDef.valueOptions.find(
          ({ value: optionValue }) => value === optionValue
        );
        if (option) return option.label;
      },
      align: "center",
      headerAlign: "center",
    },
    {
      field: "us",
      headerName: "Nome Utente",
      minWidth: 150,
      width: 200,
      flex: 1,
      editable: true,
    },
    {
      field: "pd",
      headerName: "Password",
      minWidth: 150,
      width: 200,
      flex: 1,
      editable: true,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 150,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        if (isEditMode) {
          return [
            <GridActionsCellItem
              icon={<CheckIcon />}
              label="Save"
              className="textPrimary"
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<ClearIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ]; //

  const handleSnackbar = (message, severity) => {
    dispatch(showSnackbar({ message: message, severity: severity }));
  };

  async function servicesNewUpdate(data) {
    const token = sessionStorage.getItem("token");

    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token,
          data: data,
          action: "updateBot",
        }),
      };

      const response = await fetch(
        "https://devops.mobilityexpress.it/api/servicesNewUpdate",
        requestOptions
      );

      const responseData = await response.json();
      if (responseData.status === 1) {
        handleSnackbar(response.message || savedDataMessage, "success");
      } else {
        handleSnackbar(response.message || generalErrorMessage, "error");
      }
    } catch (error) {
      return error;
    }
  }

  {
    /* 1. (Fatto) Nuova icona vicino alla targa del bot nella modale che richiama una nuova API
    2. (Fatto) Nuovo pulsante vicino al pulsante di Aggiorna icona ADD Tooltip:(Crea servizio/bot), si apre sempre la stessa modale, con un nuovo tasto nella popover servizio 'Crea nuovo servizio' 
    3. (Fatto) Cambiamento bot e stato posizione, si troveranno nella tabella preventivi vicino a ricerca. Nella settings ci sarà un datagrid come nella sezione profili. 
    4. (Fatto) Modificare la tabella in modo che bot e errore la filtrino. 
    5. (Fatto) Aggiungere la snackbar quando si clicca salva. 
    6. Fare il refresh come quello in rcAuto con snackbar.
    7. (Fatto)Tasti nella settings 
    8. (Fatto: da fare il funzionamento con la modale)Chip Crea nuovo servizio apre una modale 
    9. Aggiornare con la nuova api nella nuova popover (Nome servizio, stato: Attivo/Non Attivo)  */
  }

  return (
    <Card sx={{ mt: 4 }}>
      <CardContent sx={{ p: 0 }}>
        <div style={{ width: "100%", height: 235 }}>
          <DataGrid
            rowsPerPageOptions={[5, 10, 20]}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            columns={columns}
            getRowId={(bot) => bot.nome}
            rowHeight={40}
            pagination
            headerHeight={30}
            rows={info}
            editMode="row"
            allowUpdating={true}
            rowModesModel={rowModesModel}
            onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
            experimentalFeatures={{ newEditingApi: true }}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  // Hide columns status and traderName, the other columns will remain visible
                  idUid: false,
                },
              },
            }}
          />
        </div>
      </CardContent>
    </Card>
  );
};

export default Settings;
