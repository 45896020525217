import React, { useState } from "react";
import styled from "@emotion/styled";
import {
  Box,
  Popover as MuiPopover,
  Typography,
  Button as MuiButton,
  CircularProgress,
} from "@mui/material";
import useAuth from "../../../../hooks/useAuth";
import { generalErrorMessage, rebootMessage } from "../../rcAuto/constant";
import { useDispatch } from "react-redux";
import { showSnackbar } from "../../../../redux/slices/snackbar";

const Popover = styled(MuiPopover)`
  .MuiPaper-root {
    width: 300px;
    padding: 16px;
    ${(props) => props.theme.shadows[1]};
    border: 1px solid ${(props) => props.theme.palette.divider};
  }
`;

const ContentBox = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const FlexBox = styled(Box)`
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: center;
`;

const ButtonBox = styled(Box)`
  display: flex;
  gap: "16px";
  width: 100%;
  justify-content: center;
  margin-top: 16px;
`;

const Button = styled(MuiButton)`
  margin: 0 8px;
`;

const PopoverRefresh = ({
  idUID,
  targa,
  isRebootPopoverOpen,
  handleCloseRebootPopup,
  rebootRef,
}) => {
  const { rcAutoNewUpdate } = useAuth();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleSnackbar = (message, severity) => {
    dispatch(showSnackbar({ message: message, severity: severity }));
  };

  //Fetch Function

  const handleReboot = () => {
    const data = {
      idUID,
      targa,
    };
    setLoading(true);
    rcAutoNewUpdate(data, "reboot")
      .then((response) => {
        if (response.status === 1) {
          handleSnackbar(response.message || rebootMessage, "success");
          handleCloseRebootPopup();
        } else {
          handleSnackbar(response.message || generalErrorMessage, "error");
        }
      })
      .catch((err) => {
        handleSnackbar(err.message || generalErrorMessage, "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Popover
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      anchorEl={rebootRef}
      onClose={handleCloseRebootPopup}
      open={isRebootPopoverOpen}
    >
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "8px 0",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <ContentBox>
          <FlexBox>
            <Typography variant="body2" sx={{ marginTop: "8px" }}>
              Si desidera eseguire nuovamente la ricerca per la targa
              selezionata?
            </Typography>
          </FlexBox>
          <ButtonBox>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleCloseRebootPopup}
            >
              No
            </Button>
            <Button variant="contained" color="primary" onClick={handleReboot}>
              Si
            </Button>
          </ButtonBox>
        </ContentBox>
      )}
    </Popover>
  );
};

export default PopoverRefresh;
