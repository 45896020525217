import React, { useEffect, useState } from "react";
import { Typography, Grid, FormControl, Divider, Button, Accordion, AccordionSummary, AccordionDetails, CardContent, TextField } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DatePicker } from "@mui/x-date-pickers";

const parseDate = (dateString) => {
  if (!dateString) return null;
  const [day, month, year] = dateString.split("/");
  return new Date(year, month - 1, day);
};
const Cliente = ({
  clientPlaceholders, 
  handleInputChange, 
  tabData,           
  handleSubmit,      
  activeTab,         
  clienteDetails      
}) => {
  const [formData, setFormData] = useState([]);
  

  useEffect(() => {
    if (clienteDetails && clienteDetails.generale && clienteDetails.generale.dataset) {
      const formDataFromAPI = clienteDetails.generale.dataset || [];

      const initialValues = {};
      clientPlaceholders.forEach((client) => {
        let value = client.value;

        // Se il campo è di tipo data, usiamo la funzione parseDate per gestirlo
        if (client.type === "date") {
          value = parseDate(client.value);
        }

        initialValues[client.label] = value;
      });

      handleInputChange("Cliente", initialValues);
      setFormData(formDataFromAPI);
    }
  }, [clienteDetails, clientPlaceholders, handleInputChange]);

  const handleDateChange = (date, fieldName) => {
    handleInputChange(
      "Cliente",
      fieldName,
      date ? date.toLocaleDateString("it-IT") : ""
    );
  };

  const renderInputField = (client) => {
    const value = tabData.values[client.label] || client.value || null;

    if (client.type === "date") {
      return (
        <DatePicker
          label={`${client.label} ${!client.editable ? "" : "*"}`}
          name={client.label}
          disabled={!Boolean(client.editable)}
          value={value instanceof Date ? value : parseDate(client.value)}
          onChange={(date) => handleDateChange(date, client.label)}
          renderInput={(params) => <TextField {...params} fullWidth />}
          inputFormat="dd/MM/yyyy"
        />
      );
    } else {
      return (
        <TextField
          disabled={!client.editable}
          label={`${client.label} ${!client.editable ? "" : "*"}`}
          name={client.label}
          value={value}
          onChange={(event) =>
            handleInputChange("Cliente", client.label, event.target.value)
          }
          fullWidth
        />
      );
    }
  };

  return (
    <>
      <CardContent>
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Dati Cliente</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={3}>
              {clientPlaceholders?.map((client) => (
                <Grid item xs={12} sm={4} key={client.label}>
                  <FormControl fullWidth>{renderInputField(client)}</FormControl>
                </Grid>
              ))}
            </Grid>

            <Divider style={{ margin: "20px 0" }} />

          </AccordionDetails>
        </Accordion>
      </CardContent>
    </>
  );
};

export default Cliente;
