import React, { useReducer, useState, useEffect } from "react";
import { NavLink, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router-dom"
import {
  Button,
  Divider,
  Grid,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  CardContent
} from "@mui/material";
import useAuth from "../../../hooks/useAuth";
import { useSelector } from "react-redux";
import UserNE from "./nonEditables/UserNE";
import VehicleNE from "./nonEditables/VehicleNE";
import Veicolo from "./tabs/Veicolo";
import Preventivi from "./tabs/Preventivi";
import Cliente from "./tabs/Cliente";
import AltriRami from "./tabs/AltriRami";
import { Breadcrumbs, TabContent, TabsWrapper, Paper, Card, Spacer } from "./style";
import { Edit, X } from "react-feather";
import "animate.css";
import AgenteNE from "./nonEditables/Agente";

const RcAutoDetails = () => {
  const { getRCAutoDetails } = useAuth();
  const [datiUser, setDatiUser] = useState([]);
  const [tabs, setTabs] = useState({});
  const [rcDetails, setRcDetails] = useState({});
  const [activeTab, setActiveTab] = useState("Preventivi");
  const [laterals, setLaterals] = useState({});
  const [placeholders, setPlaceholders] = useState({
    vehiclePlcholders: [],
    clientPlcholders: [],
    preventiviPlcholders: [],
    altriRamiPlchld: []
  });

  const { idTarga } = useParams();
  const navigate = useNavigate()
  const role = useSelector((state) => state.role.role);
  const isAdm = ["supAdm", "adm"].includes(role);

  useEffect(() => {
    getRCAutoDetails(idTarga).then((res) => {
      const { infoVeicolo, infoPiva, infoCliente, infoAgente } = res;

      //Risposta vuota o errore
      res.status === 0 && navigate("pages/rcauto")

      setRcDetails(res);
      setLaterals({ infoVeicolo, infoPiva, infoCliente, infoAgente });
      setActiveTab(Object.keys(res.tabs[0])[0]);

      const tbs = {}
      res.tabs.forEach(obj => {
        const chiave = Object.keys(obj)[0];
        tbs[chiave] = obj[chiave];
      });

      setTabs(tbs);
    });
  }, [idTarga]);

  useEffect(() => {
    const newPlaceholders = {
      vehiclePlcholders: [],
      clientPlcholders: [],
      preventiviPlcholders: [],
      altriRamiPlchld: []
    };

    for (const tab in tabs) {
      if (tabs.hasOwnProperty(tab)) {
        let placeholderKey = "";
        switch (tab) {
          case "Veicolo":
            placeholderKey = "vehiclePlcholders";
            break;
          case "Cliente":
            placeholderKey = "clientPlcholders";
            break;
          case "Preventivi":
            placeholderKey = "preventiviPlcholders";
            break;
          case "Altri Rami":
            placeholderKey = "altriRamiPlchld";
            break;
          default:
            continue;
        }

        if (tabs[tab].generale && tabs[tab].generale.dataset) {

          newPlaceholders[placeholderKey] = tabs[tab].generale.dataset.map(
            (item) => ({
              label: item.label,
              position: item.position,
              editable: Boolean(item.editable),
              regex: item.regex,
              errRegex: item.errRegex,
              type: item.type,
              value: item.value
            })
          ) || [];
        }
      }
    }

    setPlaceholders(newPlaceholders);
  }, [tabs]);

  const {
    vehiclePlcholders,
    clientPlcholders,
    preventiviPlcholders,
    altriRamiPlchld
  } = placeholders;

  const initialState = (placeholders) =>
    Object.fromEntries(placeholders.map((pl) => [pl.value, ""]));

  const initialErrors = (placeholders) =>
    Object.fromEntries(placeholders.map((pl) => [pl.value, ""]));

  const initialStateData = {
    Cliente: {
      values: initialState(clientPlcholders),
      errors: initialErrors(clientPlcholders)
    },
    Veicolo: {
      values: initialState(vehiclePlcholders),
      errors: initialErrors(vehiclePlcholders)
    }
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case "SET_FIELD_VALUE":
        return {
          ...state,
          [action.tab]: {
            ...state[action.tab],
            values: {
              ...state[action.tab].values,
              [action.field]: action.value
            },
            errors: {
              ...state[action.tab].errors,
              [action.field]: action.error
            }
          }
        };
      case "SET_ERRORS":
        return {
          ...state,
          [action.tab]: {
            ...state[action.tab],
            errors: {
              ...state[action.tab].errors,
              ...action.errors
            }
          }
        };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialStateData);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleInputChange = (tab, field, value) => {
    if (
      [
        "dataAcquisto",
        "dataImmatricolazione",
        "inizioCopertura",
        "fineCopertura"
      ].includes(field) &&
      typeof value === "string"
    ) {
      const [day, month, year] = value.split("/");
      value = new Date(year, month - 1, day);
    }

    const error = validateField(tab, field, value);
    dispatch({
      type: "SET_FIELD_VALUE",
      tab,
      field,
      value,
      error
    });
  };

  const handleSubmit = (tab) => {
    const tabData = state[tab];
    let errors = {};
    let hasError = false;

    Object.keys(tabData.values).forEach((field) => {
      const placeholder =
        tab === "Cliente"
          ? clientPlcholders.find((pl) => pl.value === field)
          : vehiclePlcholders.find((pl) => pl.value === field);

      if (placeholder && placeholder.editable && !tabData.values[field]) {
        hasError = true;
        errors[field] = "Questo campo è obbligatorio";
      }
    });

    if (hasError) {
      dispatch({
        type: "SET_ERRORS",
        tab,
        errors
      });
    } else {
      console.log("Submitting form data: ", tabData.values);
    }
  };
  const validateField = (tab, fieldName, value) => {
    const tabStructure = tabs[tab];
    if (!tabStructure) return "";

    let dataset;
    if (tabStructure.generale) {
      dataset = tabStructure.generale.dataset;
    } else if (tabStructure.Proprietà) {
      dataset = tabStructure.Proprietà.dataset;
    } else {
      return "";
    }

    const field = dataset.find(
      (item) => item.label === fieldName || item.value === fieldName
    );
    if (!field) return "";

    const regex = new RegExp(field.regex);
    const errRegex = field.errRegex;

    return regex.test(value) ? "" : errRegex;
  };

  const getTabData = (tab) => {
    const tabData = state[tab];

    switch (tab) {
      case "Veicolo":
        return (
          <Veicolo
            className="animate__animated animate__fadeIn"
            tabData={tabData}
            laterals={laterals}
            handleInputChange={handleInputChange}
            handleSubmit={handleSubmit}
            activeTab={activeTab}
            vehiclePlcholders={vehiclePlcholders}
            rcDetails={rcDetails}
          />
        );
      case "Cliente":
        return (
          <Cliente
            tabData={tabData}
            handleInputChange={handleInputChange}
            clientPlaceholders={clientPlcholders}
            rcDetails={rcDetails}
          />
        );
      case "Preventivi":
        return <Preventivi />;
      case "Altri Rami":
        return <AltriRami />;
      default:
        return <>Tab non esistente</>;
    }
  };

  const renderTabs = () => {
    if (!tabs || typeof tabs !== "object") {
      return null;
    }

    return Object.keys(tabs).map((tabName, index) => (
      <Tab label={tabName} value={tabName} key={index} />
    ));
  };

  return (
    <>
      <Typography variant="h3" mb="2rem" gutterBottom display="inline">
        Dettagli targa {laterals.infoVeicolo?.targa}
      </Typography>

      <Divider sx={{ my: 6 }} />

      <Grid container spacing={6} direction="column">
        <Spacer sx={{ my: 6 }} />
        <Grid item xs={12} lg={8} xl={9} sx={{ width: "100%" }}>
          <Grid container spacing={2} direction="row" flexWrap="nowrap">
            {laterals.infoCliente && (
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <UserNE data={laterals.infoCliente} />
              </Grid>
            )}
            {laterals.infoVeicolo && (
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <VehicleNE data={laterals.infoVeicolo} />
              </Grid>
            )}
            {laterals.infoAgente && Object.keys(laterals.infoAgente).length > 0 && (
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <AgenteNE data={laterals.infoAgente} />
              </Grid>
            )}
          </Grid>
        </Grid>

        <Grid item xs={12} lg={8} xl={9} sx={{ width: "100%" }}>
          <TabsWrapper>
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              allowScrollButtonsMobile
              aria-label="scrollable force tabs example"
            >
              {renderTabs()}
            </Tabs>
            <TabContent>
              <Card>
                <CardContent className="">
                  {getTabData(activeTab)}


                </CardContent>
              </Card>
            </TabContent>
          </TabsWrapper>
        </Grid>
      </Grid>
    </>
  );
};

export default RcAutoDetails;
