import { CardContent, Hidden, Paper, useMediaQuery } from "@mui/material";
import { Card, Grid, Spacer, Typography, AboutIcon } from "../style";
import { Briefcase, Home, Mail, MapPin, Phone, User, Menu } from "react-feather";
import HomeWorkOutlinedIcon from "@mui/icons-material/HomeWorkOutlined"
import { PinDropOutlined } from "@mui/icons-material";

function UserNE({ data, className }) {
  const isMobile = useMediaQuery('(max-width:600px)')

  return (
    <>
      {isMobile ? (
        <Paper mb={6} className={className} sx={{ height: "11rem", width: "300%" }} elevation={13} >
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Cliente
            </Typography>
            <Spacer mb={4} />
            <Grid container direction="row" alignItems="center" mb={2}>
              <Grid item>
                <AboutIcon>
                  <User />
                </AboutIcon>
              </Grid>
              <Grid item>{data?.cliente}</Grid>
            </Grid>
            <Grid container direction="row" alignItems="center">
              <Grid item>
                <AboutIcon>
                  <HomeWorkOutlinedIcon />
                </AboutIcon>
              </Grid>
              <Grid item>{data?.residenza}</Grid>
            </Grid>
            <Grid container direction="row" alignItems="center" mt={2.5}>
              <Grid item>
                <AboutIcon>
                  <PinDropOutlined />
                </AboutIcon>
              </Grid>
              <Grid item>{data?.indirizzo}</Grid>
            </Grid>
          </CardContent>
        </Paper>) : (
        <Paper mb={6} sx={{ height: "11rem" }} elevation={13} >
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Cliente
            </Typography>
            <Spacer mb={4} />
            <Grid container direction="row" alignItems="center" mb={2}>
              <Grid item>
                <AboutIcon>
                  <User />
                </AboutIcon>
              </Grid>
              <Grid item>{data?.cliente}</Grid>
            </Grid>
            <Grid container direction="row" alignItems="center">
              <Grid item>
                <AboutIcon>
                  <HomeWorkOutlinedIcon />
                </AboutIcon>
              </Grid>
              <Grid item>{data?.residenza}</Grid>
            </Grid>
            <Grid container direction="row" alignItems="center" mt={2.5}>
              <Grid item>
                <AboutIcon>
                  <PinDropOutlined />
                </AboutIcon>
              </Grid>
              <Grid item>{data?.indirizzo}</Grid>
            </Grid>
          </CardContent>
        </Paper>
      )}
    </>
  );
}

export default UserNE;
