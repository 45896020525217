import { CardContent, Hidden, Paper, useMediaQuery } from "@mui/material";
import { Card, Grid, Spacer, Typography, AboutIcon } from "../style";
import { Briefcase, Home, Mail, MapPin, Phone, User, Menu } from "react-feather";
import HomeWorkOutlinedIcon from "@mui/icons-material/HomeWorkOutlined"
import { HouseOutlined, PhoneAndroidOutlined, PinDropOutlined } from "@mui/icons-material";

function AgenteNE({ data, className }) {

    const isMobile = useMediaQuery('(max-width:600px)')

    return (
        <Hidden smDown>
            <Paper mb={6} sx={{ height: "11rem" }} elevation={13} >
                    <CardContent>
                        <Typography variant="h6" gutterBottom>
                            Agente
                        </Typography>
                        <Spacer mb={4} />
                        <Grid container direction="row" alignItems="center" mb={2}>
                            <Grid item>
                                <AboutIcon>
                                    <User />
                                </AboutIcon>
                            </Grid>
                            <Grid item>{data?.Nome} {data?.Cognome}</Grid>
                        </Grid>
                        <Grid container direction="row" alignItems="center">
                            <Grid item>
                                <AboutIcon>
                                    <HouseOutlined />
                                </AboutIcon>
                            </Grid>
                            <Grid item>{data?.azienda}</Grid>
                        </Grid>
                        <Grid container direction="row" alignItems="center" mt={2.5}>
                            <Grid item>
                                <AboutIcon>
                                    <PhoneAndroidOutlined />
                                </AboutIcon>
                            </Grid>
                            <Grid item>{data?.cellulare}</Grid>
                        </Grid>
                    </CardContent>
                </Paper>
        </Hidden>
    );
}

export default AgenteNE;
