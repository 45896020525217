import React, { useRef, useState } from "react";
import {
  Box,
  CircularProgress,
  Link,
  Chip as MuiChip,
  IconButton,
  Tooltip,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import styled from "@emotion/styled";
import PopoverTarga from "./PopoverTarga";
import PopoverUser from "./PopoverUser";
import useAuth from "../../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { Person, AccountBalance } from "@mui/icons-material";
import GridOnIcon from "@mui/icons-material/GridOn";
import GavelIcon from "@mui/icons-material/Gavel";
import ShuffleIcon from "@mui/icons-material/Shuffle";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { useSelector } from "react-redux";
import PopoverReboot from "./PopoverReboot";
import ExcelIcon from "./ExcelIcon";

const Chip = styled(MuiChip)`
  height: 20px;
  padding: 4px 0;
  font-size: 90%;
  background-color: ${(props) =>
    props.theme.palette[props.color ? props.color : "primary"]};
  color: ${(props) => props.theme.palette.common.white};
`;
// Group Fleets Columns
export const useGetFleetGroupTableColumns = () => {
  const groupColumns = [
    {
      field: "Nome Flotta",
      headerName: "Nome Flotta",
      minWidth: 150,
      width: 200,
      flex: 1,
      renderCell: (params) => {
        const { NomeFlotta, UserID } = params.row;

        const handleExcelIconClick = () => {};

        return (
          <Box
            sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          >
            <ExcelIcon handleExcelIconClick={handleExcelIconClick} />
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Box>{NomeFlotta}</Box>
              <Box
                sx={{ fontSize: "smaller", color: "gray", marginTop: "2px" }}
              >
                {UserID}
              </Box>
            </Box>
          </Box>
        );
      },
    },
    {
      field: "Stato",
      headerName: "Stato",
      minWidth: 150,
      width: 200,
      flex: 1,
      renderCell: (params) => {
        const { IdStato, Stato, DataFineRicerca, DataInizioRicerca } =
          params.row;

        const completed = IdStato === 1;
        const loading = IdStato === 2 || IdStato === 6 || IdStato === 8;
        const action = IdStato === 9;

        const ChipItem = ({ color, showLoading, clickable }) => (
          <Chip
            label={
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                {Stato}
                {showLoading && (
                  <CircularProgress
                    size={14}
                    sx={{ marginLeft: "8px", color: "white", marginTop: "1px" }}
                  />
                )}
              </Box>
            }
            color={color}
            sx={clickable ? { cursor: "pointer" } : {}}
          />
        );

        const getChip = () => {
          if (completed) return <ChipItem color="success" />;
          if (loading) return <ChipItem color="warning" showLoading />;
          if (action) return <ChipItem color="info" clickable />;
          return <ChipItem color="error" />;
        };

        return (
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              {getChip()}
            </Box>
            <Box sx={{ fontSize: "smaller", color: "gray", marginTop: "2px" }}>
              {`${DataFineRicerca} - ${DataInizioRicerca}`}
            </Box>
          </Box>
        );
      },
    },
  ];

  return groupColumns;
};

// Fleet Columns
export const useGetFleetTableColumns = () => {
  const { getData } = useAuth();
  const [userData, setUserData] = useState(null);
  const [targaData, setTargaData] = useState(null);
  const [canViewUserData, setCanViewUserData] = useState(false);
  const [canViewTargaData, setCanViewTargaData] = useState(false);
  const [isTargaPopupOpen, setIsTargaPopupOpen] = useState(false);
  const [isRebootPopupOpen, setIsRebootPopupOpen] = useState(false);
  const [isUserPopupOpen, setIsUserPopupOpen] = useState(false);
  const [currentTargaRef, setCurrentTargaRef] = useState(null);
  const [currentUserRef, setCurrentUserRef] = useState(null);
  const [currentRebootRef, setCurrentRebootRef] = useState(null);
  const role = useSelector((state) => state.role.role);
  const isAdm = ["supAdm", "adm"].includes(role);
  const rebootRefs = useRef({});
  const targaRefs = useRef({});
  const userRefs = useRef({});
  const navigate = useNavigate();
  const handleNavigation = (path) => {
    navigate(path);
    window.scrollTo(0, 0);
  };

  const handleCloseTargaPopup = () => {
    setIsTargaPopupOpen(false);
    setCurrentTargaRef(null);
  };

  const handleCloseRebootPopup = () => {
    setIsRebootPopupOpen(false);
    setCurrentRebootRef(null);
  };

  const handleCloseUserPopup = () => {
    setIsUserPopupOpen(false);
    setCurrentUserRef(null);
  };

  const handleOpenTargaPopup = (ref, targaId) => {
    getData({
      action: "getPreviewInfo",
      type: "targa",
      value: targaId,
    })
      .then((response) => {
        setTargaData(response?.data);
        setCanViewTargaData(Boolean(response?.status === 1));
        setIsTargaPopupOpen(true);
        setCurrentTargaRef(ref);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleOpenUserPopup = (ref, utenteIDUID) => {
    getData({
      action: "getPreviewInfo",
      type: "utente",
      value: utenteIDUID,
    })
      .then((response) => {
        setUserData(response?.data);
        setCanViewUserData(Boolean(response?.status === 1));
        setIsUserPopupOpen(true);
        setCurrentUserRef(ref);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fleetColumns = [
    {
      field: "Targa",
      headerName: "Targa",
      minWidth: 150,
      width: 200,
      flex: 1,
      renderCell: (params) => {
        const { targa, tipoVeicolo, idUID, rebootTarga } = params.row;
        const ref = (el) => {
          targaRefs.current[params.id] = el;
        };
        const ref2 = (el) => {
          rebootRefs.current[params.id] = el;
        };
        const canReboot = isAdm && rebootTarga && rebootTarga > 0;

        return (
          <Box
            sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          >
            <PopoverReboot
              idUID={idUID}
              targa={targa}
              isRebootPopoverOpen={
                isRebootPopupOpen &&
                currentRebootRef === rebootRefs.current[params.id]
              }
              handleCloseRebootPopup={handleCloseRebootPopup}
              rebootRef={rebootRefs.current[params.id]}
            />
            {canReboot && (
              <IconButton
                ref={ref2}
                sx={{ marginRight: "8px" }}
                onClick={() => {
                  setIsRebootPopupOpen(true); // Open the popover
                  setCurrentRebootRef(rebootRefs.current[params.id]); // Set the correct reference
                }}
              >
                <RestartAltIcon />
              </IconButton>
            )}

            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <PopoverTarga
                data={targaData}
                idUID={idUID}
                canViewTargaData={canViewTargaData}
                isTargaPopupOpen={
                  isTargaPopupOpen &&
                  currentTargaRef === targaRefs.current[params.id]
                }
                handleCloseTargaPopup={handleCloseTargaPopup}
                targaRef={targaRefs.current[params.id]}
                params={params.row}
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Link
                  sx={{ cursor: "pointer", minWidth: "62px", color: "#0062ff" }}
                  onClick={() =>
                    handleNavigation(`/pages/rcautodetails/${idUID}`)
                  }
                >
                  {targa}
                </Link>
                <IconButton
                  ref={ref}
                  onClick={() =>
                    handleOpenTargaPopup(targaRefs.current[params.id], idUID)
                  }
                  size="small"
                  sx={{
                    margin: "0 4px",
                  }}
                >
                  <InfoIcon fontSize="small" />
                </IconButton>
              </Box>

              <Box sx={{ fontSize: "smaller", color: "gray" }}>
                {tipoVeicolo}
              </Box>
            </Box>
          </Box>
        );
      },
    },
    {
      field: "Cliente",
      headerName: "Cliente",
      minWidth: 150,
      width: 200,
      flex: 1,
      renderCell: (params) => {
        const {
          clienteRagSociale,
          clienteCognome,
          clienteNome,
          comuneResidenza,
        } = params.row;
        return (
          <Box>
            <Box>
              {clienteRagSociale ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <AccountBalance />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      marginLeft: "8px",
                    }}
                  >
                    <Box>{clienteRagSociale}</Box>
                    <Box sx={{ fontSize: "smaller", color: "gray" }}>
                      {params.row.comuneSedeLegale}
                    </Box>
                  </Box>
                </Box>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Person />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      marginLeft: "8px",
                    }}
                  >
                    <Box>{`${clienteCognome} ${clienteNome}`}</Box>
                    <Box sx={{ fontSize: "smaller", color: "gray" }}>
                      {comuneResidenza}
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        );
      },
    },
    {
      field: "EseguitaDa",
      headerName: "Eseguita da",
      minWidth: 150,
      width: 200,
      flex: 1,
      align: "left",
      renderCell: (params) => {
        const { utenteIDUID, utenteCognome, utenteNome, utenteAzienda } =
          params.row;
        const ref = (el) => {
          userRefs.current[params.id] = el;
        };

        return (
          <Box>
            <PopoverUser
              data={userData}
              canViewUserData={canViewUserData}
              utenteIDUID={utenteIDUID}
              isUserPopupOpen={
                isUserPopupOpen &&
                currentUserRef === userRefs.current[params.id]
              }
              handleCloseUserPopup={handleCloseUserPopup}
              userRef={userRefs.current[params.id]}
              params={params.row}
            />
            <Link
              sx={{ cursor: "pointer", color: "#0062ff" }}
              onClick={() => handleNavigation(`/pages/profile/${utenteIDUID}`)}
            >{`${utenteCognome} ${utenteNome}`}</Link>
            <IconButton
              ref={ref}
              onClick={() =>
                handleOpenUserPopup(userRefs.current[params.id], utenteIDUID)
              }
              size="small"
              sx={{
                margin: "0 8px",
              }}
            >
              <InfoIcon fontSize="small" />
            </IconButton>
            <Box sx={{ fontSize: "smaller", color: "gray" }}>
              {utenteAzienda}
            </Box>
          </Box>
        );
      },
    },
    {
      field: "Visura",
      headerName: "Visura",
      minWidth: 150,
      width: 200,
      flex: 1,
      renderCell: (params) => {
        const { idStatoRicerca, statoDesc, note, dataCreazione } = params.row;

        const completed = idStatoRicerca === 1;
        const loading =
          idStatoRicerca === 2 || idStatoRicerca === 6 || idStatoRicerca === 8;
        const action = idStatoRicerca === 9;

        const ChipItem = ({ color, showLoading, clickable }) => (
          <Chip
            label={
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                {statoDesc}
                {showLoading && (
                  <CircularProgress
                    size={14}
                    sx={{ marginLeft: "8px", color: "white", marginTop: "1px" }}
                  />
                )}
              </Box>
            }
            color={color}
            sx={clickable ? { cursor: "pointer" } : {}}
          />
        );

        const getChip = () => {
          if (completed) return <ChipItem color="success" />;
          if (loading) return <ChipItem color="warning" showLoading />;
          if (action) return <ChipItem color="info" clickable />;
          return <ChipItem color="error" />;
        };

        return (
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              {getChip()}
              {note && (
                <Tooltip
                  title={note.split("|").map((note, index) => (
                    <div key={index}>{note}</div>
                  ))}
                >
                  <IconButton size="small" sx={{ marginLeft: "8px" }}>
                    <InfoIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
            <Box sx={{ fontSize: "smaller", color: "gray", marginTop: "2px" }}>
              {dataCreazione}
            </Box>
          </Box>
        );
      },
    },
    {
      field: "Preventivi",
      headerName: "Preventivi",
      minWidth: 150,
      width: 200,
      flex: 1,
      renderCell: (params) => {
        const {
          idStatoPreventivi,
          notePreventivi,
          preventiviInLavorazione,
          preventiviTotale,
          statoPreventiviDesc,
          idSceltaPreventivo,
          sceltaPreventivoDesc,
        } = params.row;

        const completed = idStatoPreventivi === 1;
        const loading =
          idStatoPreventivi === 2 ||
          idStatoPreventivi === 6 ||
          idStatoPreventivi === 8;

        const ProgressIndicator = () => (
          <Box sx={{ marginLeft: "8px" }}>
            {`${preventiviInLavorazione} / ${preventiviTotale}`}
          </Box>
        );

        const ChipItem = ({ color, showProgress, showLoading }) => (
          <Chip
            label={
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                {statoPreventiviDesc}
                {showLoading && (
                  <CircularProgress
                    size={14}
                    sx={{ marginLeft: "8px", color: "white", marginTop: "1px" }}
                  />
                )}
                {showProgress && preventiviTotale > 0 && <ProgressIndicator />}
              </Box>
            }
            color={color}
          />
        );

        const RenderIcon = () => {
          const iconStyle = {
            width: "16px",
            height: "16px",
            marginLeft: "6px",
            marginRight: "-4px",
          };
          switch (idSceltaPreventivo) {
            case 1:
              return <GridOnIcon sx={iconStyle} />;
            case 2:
              return <GavelIcon sx={iconStyle} />;
            case 3:
              return <ShuffleIcon sx={iconStyle} />;
            default:
              return null;
          }
        };

        const getChip = () => {
          if (completed) return <ChipItem color="success" showProgress />;
          if (loading)
            return <ChipItem color="warning" showProgress showLoading />;
          return <ChipItem color="error" showProgress />;
        };

        return (
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              {getChip()}
              {notePreventivi && (
                <Tooltip
                  title={notePreventivi.split("|").map((note, index) => (
                    <div key={index}>{note}</div>
                  ))}
                >
                  <IconButton size="small" sx={{ marginLeft: "8px" }}>
                    <InfoIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
            {idSceltaPreventivo > 0 && (
              <Chip
                sx={{ marginTop: "4px" }}
                color="warning"
                label={
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    {sceltaPreventivoDesc}
                    <RenderIcon />
                  </Box>
                }
              />
            )}
          </Box>
        );
      },
    },
  ];

  return fleetColumns;
};
