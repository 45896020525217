import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/system";
import React, { useState } from "react";

const NuovoServizio = ({ open, setOpen }) => {
  const [servizio, setServizio] = useState("");
  const [stato, setStato] = useState();

  const theme = useTheme();

  const handleClose = () => {
    setOpen(false);
    setStato("");
  };

  const handleChangeServizio = (e) => {
    setServizio(e.target.value);
  };

  const handleClickStato = () => {
    setStato();
  };

  const handleChangeStato = (e) => {
    setStato(e.target.value);
  };

  return (
    <Dialog
      open={open}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            backgroundColor:
              theme.palette.mode === "dark" ? "#1B2635" : "#F7F9FC",
          },
        },
      }}
    >
      <DialogTitle>Crea nuovo servizio</DialogTitle>
      <DialogContent>
        <form
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
          }}
        >
          <FormControl
            m={2}
            sx={{ marginTop: 4 }}
            style={{ minWidth: 120 }}
            size="small"
          >
            <TextField
              size="small"
              label="Servizio"
              onChange={handleChangeServizio}
            />
          </FormControl>
          <FormControl m={2} style={{ minWidth: 120 }} size="small">
            <InputLabel id="stato">Stato</InputLabel>
            <Select
              labelId="stato"
              id="stato"
              label="Stato"
              value={stato}
              onChange={handleChangeStato}
              size="small"
            >
              <MenuItem
                onClick={() => handleClickStato("Attivo")}
                sx={{ height: 30 }}
                value="Attivo"
              >
                Attivo
              </MenuItem>
              <MenuItem
                onClick={() => handleClickStato("Attivo")}
                sx={{ height: 30 }}
                value="Non Attivo"
              >
                Non Attivo
              </MenuItem>
            </Select>
          </FormControl>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          color="primary"
          sx={{ marginRight: "8px" }}
        >
          Chiudi
        </Button>
        <Button
          onClick={handleClose}
          variant="contained"
          sx={{ marginRight: "8px" }}
        >
          Crea
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NuovoServizio;
