import { TableContainer, Paper, Chip, Table, TableHead, TableRow, TableCell, TableBody, Typography, TablePagination } from '@mui/material';
import React, { useState } from "react"

export const SinistriTable = ({ data }) => {
  const years = data.map((item) => item.anno);

  return (
    <TableContainer component={Paper} sx={{ border: '2px solid gray' }}>
      <Table aria-label="sinistri table">
        <TableHead>
          <TableRow>
            <TableCell rowSpan={2} align="center" colSpan={1}>Sinistri</TableCell>
            {years.map((year, index) => (
              <TableCell align="center" key={index}>{year}</TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody sx={{ fontSize: "10px" }}>
          <TableRow colSpan={years.length + 1} style={{ fontWeight: 'bold', translateX: "-5px", backgroundColor: '#0366fc', width: "100%" }}>
            <TableCell >Sinistri pagati con responsabilità principale</TableCell>
            {data.map((row, index) => {
              return row.principali > 0 ? (<TableCell><Chip sx={{ backgroundColor: "#f44336", translate: "5px 0" }} label={row.principali} /></TableCell>) : <TableCell sx={{ translate: "12px 0" }}>{row.principali}</TableCell>
            }
            )}
          </TableRow>
          <TableRow>
            <TableCell>di cui con danni a COSE</TableCell>
            {data.map((row, index) => (
              <TableCell key={index} align="center"  >
                {row.principaliCose}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell>di cui con danni a PERSONE</TableCell>
            {data.map((row, index) => (
              <TableCell key={index} align="center" >
                {row.principaliPersone}
              </TableCell>))}
          </TableRow>
          <TableRow>
            <TableCell>di cui con danni a MISTI (persone+cose)</TableCell>
            {data.map((row, index) => (
              <TableCell key={index} align="center" >
                {row.principaliMisti}
              </TableCell>))}
          </TableRow>
          <TableRow colSpan={years.length + 1} style={{ fontWeight: 'bold', translateX: "-5px", backgroundColor: '#0366fc', width: "100%" }}>
            <TableCell>Sinistri pagati con responsabilità paritaria</TableCell>
            {data.map((row, index) => {
              return row.paritari > 0 ? (<TableCell><Chip sx={{ background: "#ffa726", translate: "5px 0" }} label={row.paritari} /></TableCell>) : <TableCell sx={{ translate: "12px 0" }}>{row.paritari}</TableCell>
            }
            )}
          </TableRow>
          <TableRow>
            <TableCell>di cui con danni a COSE</TableCell>
            {data.map((row, index) => (
              <TableCell key={index} align="center" >
                {row.paritariCose}
              </TableCell>))}
          </TableRow>
          <TableRow>
            <TableCell>di cui con danni a PERSONE</TableCell>
            {data.map((row, index) => (
              <TableCell key={index} align="center" >
                {row.paritariPersone}
              </TableCell>))}
          </TableRow>
          <TableRow>
            <TableCell>di cui con danni a MISTI (persone+cose)</TableCell>
            {data.map((row, index) => (
              <TableCell key={index} align="center" >
                {row.paritariMisti}
              </TableCell>))}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const CopertureTable = ({ data }) => {
  // Stato per impaginazione
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  // Gestori per il cambio di pagina e righe per pagina
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Prendi i dati per la pagina corrente
  const paginatedData = data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <TableContainer component={Paper} sx={{ border: '2px solid gray' }}>
      <Table aria-label="coperture table">
        <TableHead>
          <TableRow>
            <TableCell>Targa</TableCell>
            <TableCell>Impresa</TableCell>
            <TableCell>Copertura</TableCell>
            <TableCell>N. Polizza</TableCell>
            <TableCell>Contraente</TableCell>
            <TableCell>Proprietario</TableCell>
            <TableCell>Causale</TableCell>
          </TableRow>
        </TableHead>

        <TableBody sx={{ fontSize: "10px" }}>
          {paginatedData.map((row, index) => (
            <TableRow key={index}>
              <TableCell>{row.Targa}</TableCell>
              <TableCell>
                <div>{row.Impresa}</div>
                <Typography fontSize="9px" color="white">{row.Tariffa}</Typography>
              </TableCell>
              <TableCell>
                <div>{row['Inizio copertura']} - {row['Fine copertura']}</div>
                {row["Codice IUR"] && <Typography fontSize="9px" color="white">IUR: {row['Codice IUR']}</Typography>}
              </TableCell>
              <TableCell>
                <div>{row['N. polizza']}</div>
                {row["Scadenza polizza"] && <Typography fontSize="9px">Scadenza: {row['Scadenza polizza']}</Typography>}
                {row["Black box"] && <Typography fontSize="9px" color="white">Blackbox: {row['Black box']}</Typography>}
              </TableCell>
              <TableCell>{row.Contraente}</TableCell>
              <TableCell>{row.Proprietario}</TableCell>
              <TableCell>{row.Causale}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <TablePagination
        component="div"
        count={data.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
        labelRowsPerPage="Righe per pagina"
      />
    </TableContainer>
  );
};

export const AttestatoTable = ({ data }) => {

  return (
    <TableContainer component={Paper} sx={{ border: '2px solid gray' }}>
      <Table aria-label="attestato table">
        <TableHead>
          <TableRow>
            <TableCell>Polizza/Scadenza</TableCell>
            <TableCell>Comp. Prov./Tariffa</TableCell>
            <TableCell>Contraente</TableCell>
            <TableCell>Avente Diritto</TableCell>
            <TableCell>Franchigia</TableCell>
            <TableCell>Classe Impresa</TableCell>
            <TableCell>Classe CU</TableCell>
          </TableRow>
        </TableHead>
        <TableBody sx={{ fontSize: "10px" }}>
          {data.map((row, index) => (
            <TableRow key={index}>
              <TableCell>
                <div>{row.Polizza}</div>
                {row.Scadenza && <Typography color="white">{row.Scadenza}</Typography>}
              </TableCell>
              <TableCell>
                <div>{row.Impresa}</div>
                <Typography color="white">{row["Tariffa"]}</Typography>
              </TableCell>
              <TableCell>
                {row.Contraente}
                <Typography color="white"> {row.C_CF}</Typography>
              </TableCell>
              <TableCell>
                {row["Avente diritto"]}
                <Typography color="white">{row.A_CF}</Typography>
              </TableCell>
              <TableCell>
                Num.:{row.Franchigia_Numero}
                <Typography color="white">€: {row.Franchigia_Importi}</Typography>
              </TableCell>
              <TableCell>
                Prov:{row.Impresa_Prov}
                <Typography color="white">Ass: {row.Impresa_Ass}</Typography>
              </TableCell>
              <TableCell>
                Prov:{row.CU_Prov}
                <Typography color="white">Ass: {row.CU_Ass}</Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

    </TableContainer>
  )
}

export const ProprietaTable = ({ data }) => {
  return (
    <TableContainer component={Paper} sx={{ border: '2px solid gray' }}>
      <Table aria-label="proprietà table">
        <TableHead>
          <TableRow>
            <TableCell>Targa</TableCell>
            <TableCell>Movimento / Causale</TableCell>
            <TableCell>Proprietario</TableCell>
            <TableCell>Data Operazione</TableCell>
            <TableCell>Telaio</TableCell>
          </TableRow>
        </TableHead>

        <TableBody sx={{ fontSize: "10px" }}>
          {data.map((row, index) => (
            <TableRow key={index}>
              <TableCell>
                <div>{row.Targa}</div>
                <Typography fontSize="9px" color="white">{row["Tipo veicolo"]}</Typography>
              </TableCell>
              <TableCell>
                <div>{row.Movimento}</div>
                {row.Causale && <Typography fontSize="9px" color="white">{row.Causale}</Typography>}
              </TableCell>
              <TableCell>

                {row.Intestatario}
                <Typography fontSize="9px" color="white">{row.Residenza}</Typography>
              </TableCell>
              <TableCell>{row['Data operazione']}</TableCell>
              <TableCell>{row.Telaio}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

