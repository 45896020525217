import React from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import { useState } from "react";
import { DataGrid, GridRowEditStopReasons } from "@mui/x-data-grid";
import {
  Box,
  Button,
  Grid,
  Card as MuiCard,
  Divider as MuiDivider,
  Paper,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { spacing } from "@mui/system";
import CircularProgress from "@mui/material/CircularProgress";
import { useData } from "./useData";
// import Filters from "./Filters";
import {
  useGetFleetGroupTableColumns,
  useGetFleetTableColumns,
} from "./columns";
import BarChart from "./BarChart";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

function Flotta() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const [filtersActive, setFiltersActive] = useState(isDesktop);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const groupColumns = useGetFleetGroupTableColumns();
  const fleetColumns = useGetFleetTableColumns();

  const {
    handlePageSizeChange,
    handlePageChange,
    loading,
    graphBarData,
    fleetGroupData,
    fleetTotalRows,
    fleetPageNumber,
    fleetRowsPerPage,
    groupTotalRows,
    groupPageNumber,
    groupRowsPerPage,
  } = useData();

  const switchFilterActive = () => {
    setFiltersActive((prev) => !prev);
  };

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    return updatedRow;
  };

  return (
    <React.Fragment>
      <Helmet title="RC Auto" />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h3" display="inline">
          Flotta
        </Typography>

        <Button variant="contained" onClick={() => setIsModalOpen(true)}>
          Crea Nuovo Flotta
        </Button>
      </Box>

      <Divider mt={4} />

      {loading ? (
        <Box
          sx={{
            width: "calc(100vw - 40px)",
            display: "flex",
            justifyContent: "center",
            marginTop: "16px",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Grid container>
          {/* Charts */}
          <Grid
            container
            wrap="wrap"
            sx={{
              margin: isDesktop ? "0 -16px" : "0",
              padding: "0",
              width: isDesktop ? "calc(100% + 16px)" : "100%",
            }}
            spacing={isDesktop ? 4 : 0}
          >
            <Grid item lg={6} md={6} sm={12} xs={12} sx={{ flex: 1 }}>
              <DataGrid
                autoHeight
                pageSize={groupRowsPerPage}
                onPageSizeChange={handlePageSizeChange}
                rowsPerPageOptions={[5]}
                pagination
                paginationMode="server"
                rowCount={groupTotalRows}
                page={groupPageNumber - 1}
                onPageChange={(params) => handlePageChange(params)}
                getRowId={(usersList) => usersList.idUID}
                rows={fleetGroupData}
                columns={groupColumns}
                editMode="row"
                allowUpdating={true}
                experimentalFeatures={{ newEditingApi: true }}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                localeText={{ noRowsLabel: "Non ci sono risultati" }}
                sx={{
                  "& .MuiDataGrid-footerContainer": {
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "10px",
                  },
                }}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12} sx={{ flex: 1 }}>
              <BarChart
                barChartData={graphBarData}
                // filterOptions={filterOptions}
                // setFilterByDate={setFilterByDate}
              />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <>
              <Card sx={{ marginBottom: "1rem" }}>
                Filters
                {/* <Filters
                  filtersActive={filtersActive}
                  switchFilterActive={switchFilterActive}
                  handleApplyFilters={handleApplyFilters}
                  handleRemoveFilters={handleRemoveFilters}
                  filtersLoading={filtersLoading}
                  isApplyFiltersButtonDisabled={isApplyFiltersButtonDisabled}
                  filterOptions={filterOptions}
                  filterData={filterData}
                  setFilterData={setFilterData}
                  canClearFilters={canClearFilters}
                  isRemovingFilters={isRemovingFilters}
                /> */}
              </Card>

              <Card mb={6}>
                <Paper>
                  <Box sx={{ width: "100%" }}>
                    <DataGrid
                      autoHeight
                      pageSize={fleetRowsPerPage}
                      onPageSizeChange={handlePageSizeChange}
                      rowsPerPageOptions={[5, 10, 20]}
                      pagination
                      paginationMode="server"
                      rowCount={fleetTotalRows}
                      page={fleetPageNumber - 1}
                      onPageChange={(params) => handlePageChange(params)}
                      getRowId={(usersList) => usersList.idUID}
                      rows={[]}
                      columns={fleetColumns}
                      editMode="row"
                      allowUpdating={true}
                      experimentalFeatures={{ newEditingApi: true }}
                      onRowEditStop={handleRowEditStop}
                      processRowUpdate={processRowUpdate}
                      localeText={{ noRowsLabel: "Non ci sono risultati" }}
                      sx={{
                        "& .MuiDataGrid-footerContainer": {
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          padding: "10px",
                        },
                      }}
                    />
                  </Box>
                </Paper>
              </Card>
            </>
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
}

export default Flotta;
