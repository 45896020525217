import { useEffect, useState } from "react";
import useAuth from "../../../hooks/useAuth";

export const useData = () => {
  const [loading, setLoading] = useState(true);
  const [graphBarData, setGraphBarData] = useState(null);
  const [fleetGroupData, setFleetGroupData] = useState(null);
  const [groupTotalRows, setGroupTotalRows] = useState(0);
  const [groupPageNumber, setGroupPageNumber] = useState(1);
  const [groupRowsPerPage, setGroupRowsPerPage] = useState(5);
  const [fleetTotalRows, setFleetTotalRows] = useState(0);
  const [fleetPageNumber, setFleetPageNumber] = useState(1);
  const [fleetRowsPerPage, setFleetRowsPerPage] = useState(5);
  const [isTableChanging, setIsTableChanging] = useState(false);

  const { getFleetData } = useAuth();

  // Side Effect that handles the get rc list api request initially when the page loads and then when the page number or rows change
  useEffect(() => {
    setIsTableChanging(true);
    getFleetData(groupRowsPerPage, groupPageNumber)
      .then((response) => {
        if (response.status === 1) {
          setGraphBarData(response?.data?.graphBarData);
          setGroupTotalRows(response?.data?.totalRows);
          setFleetGroupData(response?.data?.tableFlotteList);
          setIsTableChanging(false);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupRowsPerPage, groupPageNumber]);

  // function that changes the table rows (size)
  const handlePageSizeChange = (newPageSize) => {
    setGroupRowsPerPage(newPageSize);
    setGroupPageNumber(1);
  };

  // function that changes the table page
  const handlePageChange = (newPage) => {
    if (isTableChanging) {
      return;
    } else {
      setGroupPageNumber(newPage + 1);
    }
  };

  return {
    handlePageSizeChange,
    handlePageChange,
    loading,
    graphBarData,
    fleetGroupData,
    fleetTotalRows,
    isTableChanging,
    fleetPageNumber,
    fleetRowsPerPage,
    groupTotalRows,
    groupPageNumber,
    groupRowsPerPage,
  };
};
