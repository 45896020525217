import { CardContent, Hidden, useMediaQuery } from "@mui/material";
import { Card, Grid, Spacer, Typography, AboutIcon, Paper } from "../style";
import { Briefcase, Home, MapPin, Code } from "react-feather";
import { CarRepairOutlined, InfoOutlined, ModelTrainingOutlined, PinOutlined } from "@mui/icons-material";

function VehicleNE({ data, className }) {

  const isMobile = useMediaQuery('(max-width:600px)')

  return (
    <Hidden smDown>
      <Paper mb={6} className={className} sx={{ height: "11rem" ,width:isMobile ? "0" : "100%"}} elevation={12} xs={0} s={0}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Veicolo
          </Typography>
          <Spacer mb={4} />
          <Grid container direction="row" alignItems="center" mb={2}>
            <Grid item>
              <AboutIcon>
                <PinOutlined />
              </AboutIcon>
            </Grid>
            <Grid item>{data?.targa}</Grid>
          </Grid>
          <Grid container direction="row" alignItems="center" mb={2}>
            <Grid item>
              <AboutIcon>
                <InfoOutlined />
              </AboutIcon>
            </Grid>
            <Grid item>{data?.marca} | {data?.modello}</Grid>
          </Grid>

          <Grid container direction="row" alignItems="center" mb={2}>
            <Grid item>
              <AboutIcon>
                <CarRepairOutlined />
              </AboutIcon>
            </Grid>
            <Grid item>{data?.Allestimento}</Grid>
          </Grid>
        </CardContent>
      </Paper>
    </Hidden>
  );
}

export default VehicleNE;
