import React, { useEffect, useState } from "react";
import { Typography, Grid, FormControl, Divider, Button, Chip } from "../style";
import { TextField, CardContent, Paper, Accordion, AccordionSummary, Box, AccordionDetails, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useMediaQuery } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { DataGrid } from "@mui/x-data-grid";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { minWidth } from "@mui/system";
import UserNE from "../nonEditables/UserNE";
import VehicleNE from "../nonEditables/VehicleNE";
import { AttestatoTable, CopertureTable, ProprietaTable, SinistriTable } from "./Tables";

const parseDate = (dateString) => {
  if (!dateString) return null;
  const [day, month, year] = dateString.split("/");
  return new Date(year, month - 1, day);
};

const Veicolo = ({
  vehiclePlcholders,
  handleInputChange,
  tabData,
  handleSubmit,
  laterals,
  activeTab,
  rcDetails,
}) => {
  const [formData, setFormData] = useState([]);
  const [propertyTableData, setPropertyTableData] = useState([]);
  const [coverageTableData, setCoverageTableData] = useState([]);
  const [attestatoTableData, setAttestatoTableData] = useState([]);
  const [sinistriTableData, setSinistriTableData] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [pageSize, setPageSize] = useState(5);

  const isMobile = useMediaQuery("(max-width:600px)")

  const estraiObj = (array,key) => {
    for (const obj of array) {
        if (obj.hasOwnProperty(key)) {
            return obj[key];
        }
    }
    return null;
}

  useEffect(() => {
    if (rcDetails && rcDetails.tabs) {
      const veicolo = estraiObj(rcDetails.tabs,"Veicolo")

      const formDataFromAPI = veicolo.generale.dataset || []
      const propertyTableDataFromAPI = veicolo.Proprietà.dataset || [];
      const coverageTableDataFromAPI = veicolo.Coperture.dataset || [];
      const attestatoTableDataFromAPI = veicolo.Attestato.dataset || [];
      const sinistriTableDataFromAPI = veicolo.Sinistri.dataset || [];

      const addIdToRows = (rows) =>
        rows.map((row, index) => ({ ...row, id: index }));

      setFormData(formDataFromAPI);
      setPropertyTableData(addIdToRows(propertyTableDataFromAPI));
      setCoverageTableData(addIdToRows(coverageTableDataFromAPI));
      setAttestatoTableData(addIdToRows(attestatoTableDataFromAPI));
      setSinistriTableData(addIdToRows(sinistriTableDataFromAPI));

      const initialValues = {};
      vehiclePlcholders.forEach((vehicle) => {
        let value = vehicle.value;

        if (
          vehicle.label === "Data Acquisto" ||
          vehicle.label === "Data Immatricolazione" ||
          vehicle.label === "Inizio copertura" ||
          vehicle.label === "Fine copertura"
        ) {
          value = parseDate(vehicle.value);
        }

        initialValues[vehicle.label] = value;
      });

      handleInputChange("Veicolo", initialValues);
    }
  }, [rcDetails, vehiclePlcholders, handleInputChange]);

  console.log(rcDetails.tabs,estraiObj(rcDetails.tabs,"Veicolo").Proprietà)

  const handleDateChange = (date, fieldName) => {
    handleInputChange(
      "Veicolo",
      fieldName,
      date ? date.toLocaleDateString("it-IT") : ""
    );
  };

  const renderInputField = (vehicle) => {
    const value = tabData.values[vehicle.label] || vehicle.value || null;

    if (
      vehicle.label === "Data Acquisto" ||
      vehicle.label === "Data Immatricolazione" ||
      vehicle.label === "Inizio copertura" ||
      vehicle.label === "Fine copertura"
    ) {
      return (
        <DatePicker
          label={`${vehicle.label} ${!vehicle.editable ? "" : "*"}`}
          name={vehicle.label}
          disabled={!Boolean(vehicle.editable)}
          value={value instanceof Date ? value : parseDate(vehicle.value)}
          onChange={(date) => handleDateChange(date, vehicle.label)}
          renderInput={(params) => <TextField {...params} fullWidth />}
          inputFormat="dd/MM/yyyy"
        />
      );
    } else {
      return (
        <TextField
          disabled={!vehicle.editable}
          label={`${vehicle.label} ${!vehicle.editable ? "" : "*"}`}
          name={vehicle.label}
          value={value}
          onChange={(event) =>
            handleInputChange("Veicolo", vehicle.label, event.target.value)
          }
          fullWidth
        />
      );
    }
  };


  const generateRowId = (row) => row.id;

  const sinistriTabData = rcDetails?.tabs?.Veicolo?.Sinistri?.dataset?.map(({ anno, ...resto }) => resto);
  // Ordina i componenti di vehiclePlcholders per posizione

  return (
    <>
      <CardContent>
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Overview</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{display:"flex", justifyContent:"center",alignItems:"end",flexDirection:"column"}} >
            <Grid container spacing={3}>
              {vehiclePlcholders?.map((vehicle) => (
                <Grid item xs={12} sm={4} key={vehicle.label}>
                  <FormControl fullWidth>{renderInputField(vehicle)}</FormControl>
                </Grid>
              ))}
            </Grid>

            <Button
              variant="contained"
              color="primary"
              my={6}
              sx={{ width: isMobile ? "100%" : "fit-content",fontSize:"16px"}}
              disabled={Boolean(vehiclePlcholders?.map(v => !v.editable))}
              onClick={() => handleSubmit(activeTab)}
            >
              Salva
            </Button>
          </AccordionDetails>
        </Accordion>

        {/* Renderizza i componenti ordinati in base alla loro posizione */}
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Attestato</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <AttestatoTable data={attestatoTableData} />

            <Divider my={6} />

            <Typography variant="h6" my={6} >Sinistri</Typography>
            <SinistriTable data={sinistriTableData} />

          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Proprietà</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ProprietaTable data={propertyTableData} />
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Coperture</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <CopertureTable data={coverageTableData} />
          </AccordionDetails>
        </Accordion>

      </CardContent>
    </>
  );
};

export default Veicolo;
